import { Component, Vue } from "vue-property-decorator";
import i18nService from "@/services/i18n";

@Component
export default class AlterationI18nMixin extends Vue {

    private baseLink = 'modules.budget_execution_alteration.';

    public setText(text: string) {
        const link = `${this.baseLink}${text}`;
        return this.$t(link) as string;
    }
    public nameLocale(list: any[], code: string = 'code') {
    // nameLocale(list, code = 'code') {
        for (const item of list) {
            this.itemNameLocale(item, code);
        }
    }
    public itemNameLocale(item: any, code: string = 'code') {
        if (item && item !== null) {
            if (item.hasOwnProperty('name_kz')) {
                item['name_kk'] = item['name_kz'];
            }

            if (item.hasOwnProperty('nameKk')) {
                item['name_kk'] = item['nameKk'];
            }

            if (item.hasOwnProperty('nameRu')) {
                item['name_ru'] = item['nameRu'];
            }

            if (!item.hasOwnProperty('name_en')) {
                item['name_en'] = item['name_ru'];
            }

            Object.defineProperty(item, 'name', {
                configurable: true,
                get: function () {
                    if (!item.hasOwnProperty(`name_${i18nService.locale}`) && item.hasOwnProperty('value')
                        && typeof item.value !== 'number' && typeof item.value !== 'string') {
                        return item.value[`name_${i18nService.locale}`];
                    } else {
                        return item[`name_${i18nService.locale}`];
                    }
                }
            });

            if (item.hasOwnProperty(code)) {
                item['code'] = item[code];
                Object.defineProperty(item, 'localText', {
                    configurable: true,
                    get: function () {
                        if (parseInt(item['code']) === 0) { return item['name']; }
                        else { return item[code] + ' - ' + item['name']; }
                        // return item[code] + ' - ' + item['name'];
                    }
                });
            }
        }
    }
}